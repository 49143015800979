import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AppBar, Link, Toolbar, Typography } from '@material-ui/core';
import SocialNavBar from './SocialNavBar';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1
        },
        filler: {
            flexGrow: 1
        },
        link: {
            marginLeft: '20px'
        },
        logo: {
            maxWidth: '150px',
            maxHeight: '75px',
            marginRight: theme.spacing(2)
        }
    })
);

export default function FooterBar({ storeConfig }) {
    console.log('Footer', storeConfig);
    const classes = useStyles();
    const logoUrl = `${storeConfig?.logo?.data?.attributes?.url}`;

    return (
        <div className={classes.root}>
            <AppBar elevation={0} position="static" color="transparent">
                <Toolbar disableGutters={true}>
                    <img src={logoUrl} alt="Logo" className={classes.logo} />
                    <div className={classes.filler}></div>
                    <SocialNavBar storeConfig={storeConfig}></SocialNavBar>
                </Toolbar>
            </AppBar>
        </div>
    );
}
