import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import type { CSSProperties } from '@material-ui/styles';

// The editor core
import Editor, { CellPlugin } from '@react-page/editor';
// The rich text area plugins and CSS
import '@react-page/editor/lib/index.css';
import '@react-page/plugins-slate/lib/index.css';
import '@react-page/plugins-image/lib/index.css';
import '@react-page/plugins-spacer/lib/index.css';
import '@react-page/plugins-background/lib/index.css';
// The rich text area plugins
import image from '@react-page/plugins-image';
import slate from '@react-page/plugins-slate';
import spacer from '@react-page/plugins-spacer';
import divider from '@react-page/plugins-divider';
import background, { ModeEnum } from '@react-page/plugins-background';

import './App.css';
import CandyMachine from './CandyMachine';
import HeaderNavBar from './HeaderNavBar';
import FooterNavBar from './FooterNavBar';

function setFaviconEl(url) {
    var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    // @ts-ignore
    link.type = 'image/png';
    // @ts-ignore
    link.rel = 'shortcut icon';
    // @ts-ignore
    link.href = url;
    document.getElementsByTagName('head')[0].appendChild(link);
}

function setPageTitle(title: string) {
    document.title = title;
}

const useStyles = makeStyles({
    card: {
        backgroundColor: '#a100cc'
    },
    title: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontStyle: 'italic'
    },
    pos: {
        marginBottom: 12
    },
    container: {
        display: 'flex',
        justifyContent: 'space-around'
    }
});

type Styling = {
    paddingLeft?: number;
    paddingRight?: number;
    paddingBottom?: number;
    paddingTop?: number;
    border?: CSSProperties['border'];
};

// use a type here, not an interface
type CandyMachinePluginType = {
    candyMachineId: string;
    solanaRpcHost: string;
};

const { REACT_APP_BACKEND_API } = process.env;
const domainName: string = window.location.hostname;

function App() {
    const classes = useStyles();
    const [storeConfig, setStoreConfigState] = useState<any>(null);
    const [walletAddress, setWalletAddress] = useState(null);
    const [backgroundColor, setBackgroundColor] = useState<any>(null);
    const [textColor, setTextColor] = useState<any>(null);
    const [accentColor, setAccentColor] = useState<any>(null);

    const CandyMachineWrapper = (props) => {
        if (walletAddress) {
            return (
                <div>
                    {props.candyMachineId && (
                        <CandyMachine
                            walletAddress={window.solana}
                            candyMachineId={props.candyMachineId}
                            solanaRpcHost={props.solanaRpcHost}
                            storeConfig={storeConfig}
                        />
                    )}
                </div>
            );
        } else {
            return (
                <Card raised={true} variant="outlined" className={classes.card} style={{ backgroundColor: accentColor, color: textColor }}>
                    <CardContent className={classes.container}>
                        <Typography className={classes.title} variant="h5" component="h2">
                            BUY HOOD
                        </Typography>
                        <Typography variant="h5" component="h2">
                            Connect your wallet first to be able to mint
                        </Typography>
                        <Typography className={classes.title} variant="h5" component="h2">
                            &nbsp;
                        </Typography>
                    </CardContent>
                </Card>
            );
        }
    };

    type solanaRpc = 'https://explorer-api.devnet.solana.com' | 'https://explorer-api.testnet.solana.com' | 'prod';

    const myCandyMachinePlugin: CellPlugin<CandyMachinePluginType> = {
        Renderer: ({ data }) => <CandyMachineWrapper {...data}></CandyMachineWrapper>,
        id: 'myCandyMachine',
        title: 'Candy Machine plugin',
        description: 'My CandyMachine plugin just displays an ID',
        version: 1,
        controls: {
            type: 'autoform',
            schema: {
                properties: {
                    candyMachineId: {
                        type: 'string',
                        default: ''
                    },
                    solanaRpcHost: {
                        type: 'string',
                        default: 'https://explorer-api.devnet.solana.com'
                    }
                },
                required: ['candyMachineId', 'solanaRpcHost']
            }
        }
    };

    const rawCellPlugins: any[] = [slate(), image, spacer, divider];

    const cellPlugins: any[] = rawCellPlugins.map((p: CellPlugin) => ({
        ...p,
        cellStyle: (data) => ({
            paddingLeft: data.paddingLeft,
            paddingRight: data.paddingRight,
            paddingTop: data.paddingTop,
            paddingBottom: data.paddingBottom,
            border: data.border
        }),
        controls: [
            ...(p.controls
                ? [
                      {
                          title: 'Main',
                          controls: p.controls
                      }
                  ]
                : []),
            {
                title: 'Styling',
                controls: {
                    type: 'autoform',
                    columnCount: 3,
                    schema: {
                        properties: {
                            paddingLeft: {
                                type: 'number'
                            },
                            paddingRight: {
                                type: 'number'
                            },
                            paddingBottom: {
                                type: 'number'
                            },
                            paddingTop: {
                                type: 'number'
                            },
                            border: {
                                type: 'string'
                            }
                        }
                    }
                }
            }
        ]
    }));

    cellPlugins.push(
        background,
        myCandyMachinePlugin,
    );

    useEffect(() => {
        const storeApiUrl = REACT_APP_BACKEND_API + `/stores/domain/${domainName}`;
        // STRAPI filtering method below. Not needed yet.
        // const resolveStorresolveStoreUrleUrl = REACT_APP_BACKEND_API + `/stores?filters[domain_name][$eq]=${domainName}`;
        const getStoreConfig = async () => {
            const res = await axios.get(storeApiUrl);
            if (res.data?.data) {
                const solanaConfig = {
                    solana_network_stage: 'devnet',
                    solana_rpc_host: 'https://explorer-api.devnet.solana.com'
                };
                const storeConfig = res.data.data.attributes;
                console.log('CONFIG FOUND: ', storeConfig);
                setStoreConfigState({ ...storeConfig, ...solanaConfig });
                setFaviconEl(`${storeConfig?.favicon?.data?.attributes?.url}`);
                setPageTitle(storeConfig.title);
                setBackgroundColor(storeConfig.background_color);
                setTextColor(storeConfig.text_color);
                setAccentColor(storeConfig.accent_color);
            } else {
                console.warn('Handle incorrect store config');
            }
        };
        getStoreConfig();
    }, []);

    const connectWallet = async () => {
        const { solana } = window;

        if (solana) {
            const response = await solana.connect();
            console.log('Connected with Public Key:', response.publicKey.toString());
            setWalletAddress(response.publicKey.toString());
        }
    };

    const handleWalletConnect = (publicKey) =>{
        setWalletAddress(publicKey);
    }

    return (
        <div className="Root" style={{ backgroundColor, color: textColor }}>
        {storeConfig !== null ? (
            <div className="App">
                <header className="App-header">
                    <nav className="Navbar-container">
                        <HeaderNavBar storeConfig={storeConfig} onWalletConnect={handleWalletConnect}></HeaderNavBar>
                    </nav>
                </header>

                <main className="App-main">
                    {storeConfig.content != null && <Editor value={storeConfig.content} cellPlugins={cellPlugins} readOnly />}
                </main>

                <footer className="App-footer">
                    <nav className="Navbar-container">
                        <FooterNavBar storeConfig={storeConfig}></FooterNavBar>
                    </nav>
                    <p className="copyright">© {new Date().getFullYear()} {storeConfig.title}</p>
                </footer>
            </div>
        ) : (
            <div className="App">
                <header className="App-header">
                    <img src="https://nft-starter.com/images/logo-big.png" className="App-logo" alt="logo" />
                    <p>Something amazing is about to be born here!</p>
                </header>
                <main>
                    <p className="tac">No config found for this store</p>
                </main>
            </div>
        )}
        </div>
    );
}

export default App;
