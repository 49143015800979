import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AppBar, Link, Toolbar, Typography, SvgIcon, Button } from '@material-ui/core';
import SocialNavBar from './SocialNavBar';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1
        },
        filler: {
            flexGrow: 1
        },
        connectButton: {
            marginRight: theme.spacing(1)
        },
        link: {
            marginLeft: '20px',
            fontStyle: 'italic',
            textTransform: 'uppercase',
            letterSpacing: '1px'
        },
        logo: {
            maxWidth: '150px',
            maxHeight: '75px',
            marginRight: theme.spacing(2)
        }
    })
);

function PhantomWalletIcon(props) {
    return (
        <SvgIcon {...props} color="inherit" viewBox="0 0 34 34">
            <linearGradient id="a" x1=".5" x2=".5" y1="0" y2="1">
                <stop offset="0" stopColor="#534bb1" />
                <stop offset="1" stopColor="#551bf9" />
            </linearGradient>
            <linearGradient id="b" x1=".5" x2=".5" y1="0" y2="1">
                <stop offset="0" stopColor="#fff" />
                <stop offset="1" stopColor="#fff" stopOpacity=".82" />
            </linearGradient>
            <circle cx="17" cy="17" fill="url(#a)" r="17" />
            <path
                d="m29.1702 17.2071h-2.9969c0-6.1074-4.9683-11.05817-11.0975-11.05817-6.05325 0-10.97463 4.82957-11.09508 10.83237-.12461 6.205 5.71752 11.5932 11.94538 11.5932h.7834c5.4906 0 12.8497-4.2829 13.9995-9.5013.2123-.9619-.5502-1.8661-1.5388-1.8661zm-18.5479.2721c0 .8167-.67038 1.4847-1.49001 1.4847-.81964 0-1.48998-.6683-1.48998-1.4847v-2.4019c0-.8167.67034-1.4847 1.48998-1.4847.81963 0 1.49001.668 1.49001 1.4847zm5.1738 0c0 .8167-.6703 1.4847-1.4899 1.4847-.8197 0-1.49-.6683-1.49-1.4847v-2.4019c0-.8167.6706-1.4847 1.49-1.4847.8196 0 1.4899.668 1.4899 1.4847z"
                fill="url(#b)"
            />
        </SvgIcon>
    );
}

export default function HeaderNavBar({ storeConfig, onWalletConnect }) {
    console.log('Header', storeConfig);
    const classes = useStyles();
    const [walletAddress, setWalletAddress] = useState(null);
    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();
    const logoUrl = `${storeConfig?.logo?.data?.attributes?.url}`;

    const ConnectWalletButton = (props) => {
        if (!walletAddress) {
            return (
                <Button variant="outlined" color="inherit" className="cta-button connect-wallet-button" onClick={connectWallet}>
                    <PhantomWalletIcon className={classes.connectButton}></PhantomWalletIcon>Connect
                </Button>
            );
        } else {
            return null;
        }
    };

    const connectWallet = async () => {
        const { solana } = window;
        if (solana) {
            const response = await solana.connect();
            setWalletAddress(response.publicKey.toString());
            onWalletConnect(response.publicKey.toString());
        }
    };

    const checkIfWalletIsConnected = async () => {
        try {
            const { solana } = window;

            if (solana && solana.isPhantom) {
                console.log('Phantom wallet found!');

                /*
                 * The solana object gives us a function that will allow us to connect
                 * directly with the user's wallet!
                 */
                const response = await solana.connect({ onlyIfTrusted: true });
                console.log('Connected with Public Key:', response.publicKey.toString());

                /*
                 * Set the user's publicKey in state to be used later!
                 */
                setWalletAddress(response.publicKey.toString());
                onWalletConnect(response.publicKey.toString());
            } else {
                alert('Solana object not found! Get a Phantom Wallet 👻');
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const onLoad = async () => {
            await checkIfWalletIsConnected();
        };
        window.addEventListener('load', onLoad);
        return () => window.removeEventListener('load', onLoad);
    }, []);

    return (
        <div className={classes.root}>
            <AppBar elevation={0} position="static" color="transparent">
                <Toolbar disableGutters={true}>
                    <img src={logoUrl} alt="Logo" className={classes.logo} />
                    <div className={classes.filler}></div>
                    <ConnectWalletButton></ConnectWalletButton>
                    {storeConfig.header_navigation && storeConfig.header_navigation.sort((a, b) => (a.id - b.id)).map((menuItem) => (
                        <Typography variant="h6" key={menuItem.id}>
                            <Link
                                href="#"
                                color="inherit"
                                className={classes.link}
                                onClick={(e) => {
                                    let scrollToEl = document.getElementById(menuItem.anchor);
                                    e.preventDefault(); // Stop Page Reloading
                                    scrollToEl && scrollToEl.scrollIntoView({ behavior: 'smooth' });
                                }}
                            >
                                { menuItem.title }
                            </Link>
                        </Typography>
                    ))}
                    <SocialNavBar storeConfig={storeConfig}></SocialNavBar>
                </Toolbar>
            </AppBar>
        </div>
    );
}
